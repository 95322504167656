<template>
  <div>
    <h4>Fragebogen ECOG Performance Score</h4>

    <el-row class="mt-4" v-if="deviceProgress.started">
      <el-col :span="12">

        <div :class="deviceProgressMessage(deviceProgress.started)">
          <el-icon v-if="!deviceProgress.started" name="loading"></el-icon><el-icon v-else name="success"></el-icon> Verbindung wird zu Gerät {{form.device.model}} hergestellt.
        </div>

        <div :class="deviceProgressMessage(deviceProgress.connected)">
          <el-icon v-if="!deviceProgress.connected" name="loading"></el-icon><el-icon v-else name="success"></el-icon> Verbindung erfolgreich zu Gerät {{form.device.model}} hergestellt.
        </div>

        <div :class="deviceProgressMessage(deviceProgress.sent)">
          <el-icon v-if="!deviceProgress.sent" name="loading"></el-icon><el-icon v-else name="success"></el-icon> Formular wird an das Gerät {{form.device.model}} gesendet.
        </div>

        <div :class="deviceProgressMessage(deviceProgress.received)">
          <el-icon v-if="!deviceProgress.received" name="loading"></el-icon><el-icon v-else name="success"></el-icon> Formular wird auf dem Gerät {{form.device.model}} angezeigt.
        </div>

        <div :class="deviceProgressMessage(deviceProgress.received && deviceProgress.finished)">
          <el-icon v-if="!deviceProgress.finished" name="loading"></el-icon><el-icon v-else name="success"></el-icon> Patient füllt Formular aus.
        </div>

        <div :class="deviceProgressMessage(deviceProgress.finished)">
          <el-icon v-if="!deviceProgress.finished" name="loading"></el-icon><el-icon v-else name="success"></el-icon> Patient hat das Formular ausgefüllt und beendet.
        </div>

        <div v-if="deviceProgress.finished" class="my-4">

          <el-card>

          <h4>ECOG Score <span v-if="deviceProgress.result.result === null">nicht ermittelbar</span><span v-else>{{deviceProgress.result.result}}</span></h4>
          <div class="mb-3" v-if="!deviceProgress.result.valid"><el-icon class="mr-3" name="warning"></el-icon> Fragebogen wurde nicht plausibel/eindeutig ausgefüllt.</div>
          <el-switch :active-text="`${form.device.model} für eine erneute Nutzung freigeben`" v-model="deviceProgress.setDeviceReady"></el-switch>

          <el-button class="mt-4" @click="onEcogFinish">{{(deviceProgress.setDeviceReady) ? 'Gerät freigeben und zurück zum Dashboard' : 'Zurück zum Dashboard'}}</el-button>

          </el-card>
        </div>

      </el-col>
    </el-row>
    <el-row class="mt-4" v-if="!deviceProgress.started">
        <el-col :span="12">
          <el-card shadow="false">

            <template #header>
              <div  class="card-header">
                <span>Auf welchem Endgerät soll der Fragebogen erscheinen?</span>
              </div>
            </template>

            <span class="mr-3" v-for="device in devices" :key="device.id">
                  <el-tooltip v-if="(!device.available && device.connected)"  class="item" effect="dark" content="Gerät ist online aber in Benutzung" placement="bottom">
                    <el-radio v-model="form.device" :disabled="!device.available" :label="device" border><el-icon name="loading"></el-icon> {{device.model}}</el-radio>
                  </el-tooltip>
              <el-radio v-else v-model="form.device" :disabled="!device.available" :label="device" border>{{device.model}}</el-radio>
            </span>

            <el-alert :closable="false" v-if="devicelist.error" type="error">Es wurden keine verfügbaren Geräte gefunden. Ist das Gerät an? Ist die Dacy-App gestartet? Ist die Dacy-App mit dem Dacy-Server verbunden?</el-alert>


          </el-card>
          <el-card shadow="false" class="mt-3">

            <template #header>
              <div class="card-header">
                <span>Therapie</span>
              </div>
            </template>

            <el-radio v-model="form.tx" label="erstgespraech" border>Erstgespräch</el-radio><br/>
            <el-radio v-model="form.tx" label="ohne_tx" border>Verlauf/Nachsorge ohne Tx</el-radio><br/>
            <el-radio v-model="form.tx" label="orale_iv_tx" border>Orale / IV Tx</el-radio><br/>

          </el-card>

        </el-col>
        <el-col :span="11" :offset="1">

          <el-card class="mb-3" shadow="false" v-if="form.tx == 'orale_iv_tx'">

            <template #header>
              <div class="card-header">
                <span>Therapie Zyklus Nr.</span>
              </div>
            </template>

            <el-input-number v-model="form.cycle" :min="1" :max="100"></el-input-number>

          </el-card>

          <el-card shadow="false">

            <template #header>
              <div class="card-header">
                <span>Hat der Patient Deutsch als Muttersprache?</span>
              </div>
            </template>

            <el-radio-group v-model="form.is_german">
              <el-radio label="true" border>Ja</el-radio>
              <el-radio label="false" border>Nein</el-radio>
            </el-radio-group>

          </el-card>

          <div class="text-right mt-5">
            <el-button type="primary" @click="onEcogStart">Fragebogen starten</el-button>
          </div>


        </el-col>
      </el-row>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import socket from './../../socket'
import axios from "axios";

export default {
  name: "PatientEcog",
  props: [],
  components: {},
  data() {
    return {
      deviceProgress: {
        started: false,
        connected: false,
        sent: false,
        received: false,
        finished: false,
        result: null,
        setDeviceReady: true
      },
      devicelist: {
        error: false,
        loading: false
      },
      form: {
        type: 'ecog',
        device:null,
        cycle:1,
        is_german: null
      }
    }
  },
  mounted: async function () {

    await this.onDeviceList()
    socket.on('device-change', async () => {
      this.onDeviceList()
    })

    socket.on('form-received', async (device) => {
        if(device.uuid == this.form.device.uuid) {
          this.deviceProgress.received = true
        }
    })

    socket.on('form-finished', async (data) => {
      if(data.device.uuid == this.form.device.uuid) {
        this.deviceProgress.finished = true
        this.deviceProgress.result = data.result
      }
    })

  },
  computed: {
    ...mapGetters('devices', ['devices']),
    ...mapGetters('patients', ['currentPatient']),
    deviceSearchBtnIcon: function () {
      return this.devicelist.loading ? 'el-icon-loading' : 'el-icon-refresh'
    }
  },
  /*
  watch: {
    var: function (val) {
    }
  },
  */
  methods: {
    ...mapActions('devices', ['getDevices']),
    async onDeviceList() {

      this.form.device = null
      this.devicelist.error = false
      await this.getDevices()
      if(this.devices && this.devices.length === 1 && this.devices[0].available === 1) {
        this.form.device = this.devices[0]
      }
      else {
        let noDeviceAvailable = true
        for(const device of this.devices) {
          if(device.available === 1) noDeviceAvailable = false
        }
        this.devicelist.error = noDeviceAvailable
      }

    },
    async onEcogStart() {

      window.setTimeout(() => {
        this.deviceProgress.started = true
          window.setTimeout(() => {
            this.deviceProgress.connected = true
              window.setTimeout(async () => {
                this.deviceProgress.sent = true

                await axios.post('/devices/form/', {
                  form: this.form,
                  patientId: this.currentPatient.id
                })

              }, 800)
          }, 800)
      }, 800)

    },
    async onEcogFinish() {

      if(this.deviceProgress.setDeviceReady) {
        await axios.post('/devices/' + this.form.device.id + '/status', {
          available: true
        })
      }

      this.$root.closeWindow()

    },
    deviceProgressMessage(bool) {
      let classes = 'el-alert is-light'

      if(bool) classes += ' el-alert--success'
      else classes += ' el-alert--info'

      return classes
    }
  }
}
</script>
<style scoped>
.el-alert i {
  margin-right: 7px;
}

.el-alert {
  margin-bottom:7px;
}


.el-popper.is-dark {
  color: #fff;
  background: #303133;
}

.el-popper {
  position: absolute;
  border-radius: 4px;
  padding: 10px;
  z-index: 2000;
  font-size: 12px;
  line-height: 1.2;
  min-width: 10px;
  word-wrap: break-word;
  visibility: visible;
}
.el-popper.is-dark .el-popper__arrow:before {
  background: #303133;
  right: 0;
}
.el-popper__arrow:before {
  content: " ";
  transform: rotate(
      45deg
  );
  background: #303133;
  box-sizing: border-box;
}
.el-popper__arrow, .el-popper__arrow:before {
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: -1;
}
</style>
